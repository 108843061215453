import React, {useState, useEffect, useContext} from 'react';
import Button from 'react-bootstrap/Button';
import { DashboardStateContext } from '../../Dashboard/IBxxDashboard/IBxxDashboard';
import { getSubdirPaths } from '../../Dashboard/IBxxDashboard/getSubdirPaths';
import useFetch from '../../Hooks/useFetch';

const subdirPaths = getSubdirPaths();

const toTSVString = arr => `${arr.join('\t')}\n`;

const serializeTSV = (data, {
    experiment,
    outlierMetric,
    qualityOption,
    showRelativePlots
}) => {
    //make tsv string
    const headerRow = ['metric', 'sync_abs', 'async_abs', ...(showRelativePlots ? ['sync_rel', 'async_rel'] : [])];
    const rowLabels = ['numTrials', 'median', 'avg', 'sd', 'min', 'max', 'q25', 'q75'];
    const rows = rowLabels.map(label => [label, data.sync.abs[label], data.async.abs[label], ...(showRelativePlots ? [data.sync.rel[label], data.async.rel[label]] : [])]);
    const tsvString = [headerRow, ...rows].map(toTSVString).join('');

    //download tsv file
    const filename = `summary_${experiment}_${outlierMetric}_${qualityOption}.tsv`;
    const link = document.createElement('a');
    link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(tsvString));
    link.download = filename;
    link.click();
};

const makeDataUrl = (experiment, qualityOption, outlierMetric) => 
    `${window.location.origin}/data/iBxx/${experiment}/${subdirPaths.quality[qualityOption]}/${subdirPaths.filter[outlierMetric]}/summary.json`;

const DownloadTSVButtonIBxx = props => {
    const { lightMode } = props;

    //read context
    const dashboardState = useContext(DashboardStateContext);
    const {
        experiment,
        outlierMetric,
        qualityOption,
        showRelativePlots
    } = dashboardState;

    //init state
    const [dataUrl, setDataUrl] = useState(makeDataUrl(experiment, qualityOption, outlierMetric));
    const [data, setData] = useState({});

    //fetch data
    const [ fetchData, isLoading ] = useFetch({ url: dataUrl });

    //update url on context change
    useEffect(() => {
        setDataUrl(makeDataUrl(experiment, qualityOption, outlierMetric));
    }, [ experiment, outlierMetric, qualityOption ]);

    //update data on fetch success
    useEffect(() => setData(fetchData.data), [fetchData]);

    return (
        <Button 
            disabled={isLoading} 
            id="downloadTSVButton" 
            variant={ lightMode ? "outline-dark" : "outline-light"}
            size="sm" 
            onClick={ 
                () => serializeTSV(data, {
                    experiment,
                    outlierMetric,
                    qualityOption,
                    showRelativePlots
                })}
        >
            Download Summary Stats as TSV
        </Button>
    );
};

export default DownloadTSVButtonIBxx;
