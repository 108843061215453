import React, {useState, useEffect} from 'react';
import { Group } from '@visx/group';
import { scaleLinear } from '@visx/scale';
import { AxisLeft, AxisBottom } from '@vx/axis';
import { GridRows, GridColumns } from '@vx/grid';
import { Circle } from '@vx/shape';
import { Text } from '@vx/text';
import angleColorScale from '../Plots2d/angleColorScale';
import { padRange } from '../tools/padRange';

/*
    DATA: array of pairs: [y, x]        !!unintuitive order!!
*/

const Scatterplot = props => {
    const { data, label, dims, color, smallScreen, axisLabels, lightMode } = props;
    const { width, height } = dims;

    const useSolidColor = color !== "scale";

    //init state
    const [currentLabel, setCurrentLabel] = useState(label);

    //update state on prop changes
    useEffect(() => setCurrentLabel(label), [label]);

    //margins
    const marginLeft = smallScreen ? 40 : 50;
    const marginRight = 10;
    const marginBottom = 70;
    const marginTop = 20;

    // bounds
    const xMin = marginLeft;
    const xMax = width-marginRight;
    const yMin = marginTop;
    const yMax = height-marginBottom;

    //Find extrema
    const extrema1d = arr => arr.reduce((extrema, value) => {
        extrema.max = Math.max(extrema.max, value);
        extrema.min = Math.min(extrema.min, value);
        return extrema;
    }, ({ max: Number.NEGATIVE_INFINITY, min: Number.POSITIVE_INFINITY }));
    const extremaY = extrema1d(data.map(([y, x]) => y));
    const extremaX = extrema1d(data.map(([y, x]) => x));

    //Assign extrema to dimensions
    const [minYValue, maxYValue] = [extremaY.min, extremaY.max];
    const [minXValue, maxXValue] = [extremaX.min, extremaX.max]; 

    //make scales
    const domainY = padRange([minYValue, maxYValue], .1);
    const domainX = padRange([minXValue, maxXValue], .1);
    const yScale = scaleLinear({
        range: [yMax, yMin],
        round: true,
        domain: domainY,
    });
    const xScale = scaleLinear({
        range: [xMin, xMax],
        round: true,
        domain: domainX,
    });

    //Get color Scale
    const { scale, getAngleLimit } = angleColorScale(maxYValue);
    const colorScale = scale;


    const numTicksY = smallScreen ? 4 : 5;
    const numTicksX = numTicksY;
    //tick styling
    const tickLabelPropsX = () => ({
      fill: !lightMode ? "#ced4da" : "#111",
      textAnchor: 'middle',
      verticalAnchor: "middle",
      fontSize: smallScreen ? '.8em' : '.9em'
    });
    const tickLabelPropsY = () => ({
        fill: !lightMode ? "#ced4da" : "#111",
        textAnchor: 'end',
        verticalAnchor: "middle",
        dx: '-0.4em',
        fontSize: smallScreen ? '.8em' : '.9em'
    });
    

    return(
        <Group className="stripChartGroup">
            <AxisBottom
              scale={xScale}
              left={0}
              top={yMax}
              numTicks={numTicksX}
              stroke={!lightMode ? "#ced4da" : "#111"}
              tickStroke={!lightMode ? "#ced4da" : "#111"}
              tickLabelProps={tickLabelPropsX}
            />
            <AxisLeft
              scale={yScale}
              left={marginLeft}
              numTicks={numTicksY}
              stroke={!lightMode ? "#ced4da" : "#111"}
              tickStroke={!lightMode ? "#ced4da" : "#111"}
              tickLabelProps={tickLabelPropsY}
            />
            <GridRows
              scale={yScale}
              left={marginLeft}
              width={xMax-marginLeft}
              height={yMax}
              strokeDasharray="2, 2"
              numTicks={numTicksY}
              stroke={!lightMode ? "#ced4da" : "#111"}
              strokeOpacity={0.3}
              nice="true"
            />
            <GridColumns
              scale={xScale}
              left={0}
              width={xMax-marginLeft}
              top={marginTop}
              height={yMax-marginTop}
              strokeDasharray="2, 2"
              numTicks={numTicksY}
              stroke={!lightMode ? "#ced4da" : "#111"}
              strokeOpacity={0.3}
              nice="true"
            />
            {
                data.map(([yValue, xValue], i) => {
                    const c = useSolidColor ? color : colorScale(getAngleLimit(yValue, xValue));
                    return (
                        <Circle 
                            key={i}
                            cx={xScale(xValue)}
                            cy={yScale(yValue)} 
                            r={smallScreen ? 1.5 : 2.5}
                            stroke={c}
                            strokeWidth={1}
                            fill={c}
                            fillOpacity={.3}
                        />
                    );
                })
            }
            
            <Text className="axisLabel small"
                x={xMax}
                y={yMax * .98}
                textAnchor="end"
                verticalAnchor="end"
                fill="white"
            >
                {axisLabels[1]}
            </Text>

            <Text className="axisLabel small"
                x={xMin}
                y={yMin * 1.1}
                textAnchor="end"
                verticalAnchor="start"
                fill="white"
                transform={`rotate(-90, ${xMin}, ${yMin})`}
            >
                {axisLabels[0]}
            </Text>
            
            
            <Text className="axisLabel"
              x={marginLeft + (xMax-marginLeft) / 2}
              y={yMax + (height - yMax)/2 * 1.25}
              width={width*.9}
              verticalAnchor="middle"
              textAnchor="middle"
              fill="white"
            >
              {currentLabel}
            </Text>

        </Group>
    );
}
export default Scatterplot;