import React, {useState, useEffect} from 'react';
import { Group } from '@visx/group';
import { scaleLinear, scaleBand } from '@visx/scale';
import { AxisLeft, AxisBottom } from '@vx/axis';
import { GridRows } from '@vx/grid';
import { Text } from '@vx/text';
import angleColorScale from '../Plots2d/angleColorScale';
import { padRange } from '../tools/padRange';


function Stripchart(props){
    const { data, label, dims, color, smallScreen, axisLabels, lightMode } = props;
    const { width, height } = dims;

    //init state
    const [currentLabel, setCurrentLabel] = useState(label);

    //update state on prop change
    useEffect(() => setCurrentLabel(label), [label]);

    const useSolidColor = color !== "scale";

    //margins
    const marginLeft = smallScreen ? 40 : 50;
    const marginRight = 10;
    const marginBottom = 60;
    const marginTop = 20;

    // bounds
    const xMax = width-marginRight;
    const yMin = marginTop;
    const yMax = height-marginBottom;

    //calc data range
    const values = data.reduce((allValues, [sync, async]) => {
        allValues.push(sync, async);
        return allValues;
    }, []);
    const minYValue = Math.min(...values);
    const maxYValue = Math.max(...values);

    //Get color Scale
    const { scale, getAngleLimit } = angleColorScale(maxYValue);
    const colorScale = scale;

    //make scales
    const numTicksY = smallScreen ? 6 : 12;
    const domainY = padRange([minYValue, maxYValue], .1);
    const yScale = scaleLinear({
        range: [yMax, yMin],
        round: true,
        domain: domainY,
    });
    const xScale = scaleBand({
        round: true,
        range: [marginLeft, xMax],
    });

    //tick styling
    const tickLabelPropsY = () => ({
      fill: !lightMode ? "#ced4da" : "#111",
      textAnchor: 'end',
      verticalAnchor: "middle",
      dx: '-0.4em',
      dy: '0em',
      fontSize: smallScreen ? '.8em' : '.9em'
    });
    

    return(
        <Group className="stripChartGroup">
            <AxisBottom
              scale={xScale}
              left={0}
              top={yMax}
              stroke={!lightMode ? "#ced4da" : "#111"}
              hideTicks={true}
              tickValues={[]}
            />
            <AxisLeft
              scale={yScale}
              left={marginLeft}
              numTicks={numTicksY}
              stroke={!lightMode ? "#ced4da" : "#111"}
              tickStroke={!lightMode ? "#ced4da" : "#111"}
              tickLabelProps={tickLabelPropsY}
            />
            <GridRows
              scale={yScale}
              left={marginLeft}
              width={xMax-marginLeft}
              height={yMax}
              strokeDasharray="2, 2"
              numTicks={numTicksY}
              stroke={!lightMode ? "#ced4da" : "#111"}
              strokeOpacity={0.3}
              nice="true"
            />
            <Group className="strips">
              {data.map(([syncValue, asyncValue], i) => {
                return (
                  <g key={i}>
                      <line 
                          x1={marginLeft}
                          x2={xMax}
                          y1={yScale(syncValue)}
                          y2={yScale(asyncValue)}
                          stroke={useSolidColor ? color : colorScale(getAngleLimit(syncValue, asyncValue))}
                      />
                  </g>
                )
              })}
            </Group>
            <Group>
              {axisLabels.map((label, i) => {
                return (
                  <Text key={label}
                    className="axisLabel small"
                    x={i === 0 ? marginLeft : xMax}
                    y={yMax + (height - yMax) * .25}
                    width={width}
                    verticalAnchor="middle"
                    textAnchor={i === 0 ? "start" : "end"}
                    fill={!lightMode ? "##fff" : "#000"}
                  >
                    {label}
                  </Text>
                )
              })}
            </Group>
            <Text className="axisLabel"
              x={marginLeft + (xMax-marginLeft) * .5}
              y={yMax + (height - yMax) * .6}
              width={width}
              verticalAnchor="middle"
              textAnchor="middle"
              fill={!lightMode ? "##fff" : "#000"}
            >
              { currentLabel }
            </Text>

        </Group>
    );
}
export default Stripchart;