import React, { useContext } from 'react';
import Legends from '../../Legends/Legends';
import Separator from '../../Separator/Separator';
import { LegendOrdinal } from '@vx/legend'
import angleColorScale from '../../Plots2d/angleColorScale';
import { DashboardStateContext } from '../../Dashboard/IBxxDashboard/IBxxDashboard';

const IBxxLegends = props => {
    const { minWidth, maxWidth, fullWidth, smallScreen } = props;
    //read context
    const dashboardState = useContext(DashboardStateContext);
    const {
        useScatterplot,
        useColorScheme
    } = dashboardState;

    const isNeeded = useColorScheme;

    const scaleAbs = angleColorScale(1).scale;

    return isNeeded 
        ? (
            <Legends minWidth={minWidth} maxWidth={maxWidth} fullWidth={fullWidth} smallScreen={smallScreen}>
                <Separator />
                <h5>{useScatterplot ? 'Scatter plot' : 'Strip chart'}</h5>
                <h6>Slope</h6>
                <p>Color encodes the slope of a line from the normalized sync value to the normalized async value with length = 1.
                <br></br>
                <i>Normalized values are in range [0, 1].</i>
                </p>
                <LegendOrdinal 
                    scale={scaleAbs}
                    labelFormat={(value, i) => `<= ${+value.toFixed(2)} ${i===8 ? ' || >= ' : ''} ${i === 8 ? +value.toFixed(2) : ''} °`}
                    labelAlign="left"
                />
    
            </Legends>
        )
        :(<></>);
}
export default IBxxLegends;