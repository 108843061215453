import './Legends.css';

import React from 'react';

const Legends = props => {
    const { minWidth, maxWidth, children, smallScreen, fullWidth } = props;

    return (
        <div className="visContainer legends" style={smallScreen ? {width: fullWidth} : {minWidth: minWidth, maxWidth: maxWidth}}> 
            <h3>Legends</h3>
            {children}
        </div>
    );
}
export default Legends;