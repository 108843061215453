import React from 'react';
import Button from 'react-bootstrap/Button';
import './DownloadButton.css';

const serializer = () => {
    const xmlns = "http://www.w3.org/2000/xmlns/";
    const xlinkns = "http://www.w3.org/1999/xlink";
    const svgns = "http://www.w3.org/2000/svg";
    const serialize = svg => {
      svg = svg.cloneNode(true);
      const fragment = window.location.href + "#";
      const walker = document.createTreeWalker(svg, NodeFilter.SHOW_ELEMENT, null, false);
      while (walker.nextNode()) {
        for (const attr of walker.currentNode.attributes) {
          if (attr.value.includes(fragment)) {
            attr.value = attr.value.replace(fragment, "#");
          }
        }
      }
      svg.setAttributeNS(xmlns, "xmlns", svgns);
      svg.setAttributeNS(xmlns, "xmlns:xlink", xlinkns);
      const serializer = new window.XMLSerializer();
      const string = serializer.serializeToString(svg);
      return new Blob([string], {type: "image/svg+xml"});
    };
    return serialize;
  }
const serialize = el => serializer()(el);

const serializeSVGs = () => {
    const svgs = [...document.getElementsByClassName('visSVG_saveable')];
    const blobs = svgs.map(serialize);
    blobs.forEach((blob, i) => {
        const filename = `vis_${i}_${+new Date()}.svg`;
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.click();
    });
}

const DownloadButton = props => {
  const { lightMode } = props;
    return(
        <Button id="downloadButton" variant={lightMode ? "outline-dark" : "outline-light"} size="sm" onClick={serializeSVGs}>Download Plots as SVG</Button>
    );
}
export default DownloadButton;