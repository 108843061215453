import React, { useContext, useEffect } from 'react';
import UIBox from '../../UIBox/UIBox';
import DownloadButton from '../../DownloadButtons/DownloadSVGButton/DownloadButton';
import DownloadTSVButtonIBxx from '../../DownloadButtons/DownloadTSVButton/DownloadTSVButtonIBxx'
import ToggleButton from '../../ToggleButton/ToggleButton';
import Dropdown from '../../Dropdown/Dropdown';
import Separator from '../../Separator/Separator';
import ConstrainedNumericInput from '../../ConstrainedNumericInput/ConstrainedNumericInput';
import { DashboardStateContext } from '../../Dashboard/IBxxDashboard/IBxxDashboard';
import './IBxxControls.css';

const IBxxControls = props => {
    const { minWidth, maxWidth, fullWidth, smallScreen  } = props;

    //read context
    const dashboardState = useContext(DashboardStateContext);
    const {
        numBins,
        toggles, 
        setters,
        showMedian,
        darkMode,
    } = dashboardState;
    const { 
        toggleShowOutliers, 
        toggleShowMedian,
        toggleUseColorScheme, 
        toggleUseScatterplot, 
        toggleShowRelativePlots,
        toggleDarkMode,
    } = toggles;
    const { 
        setExperiment, 
        setOutlierMetric, 
        setStripchartMetric, 
        setQualityOption, 
        setNumBins 
    } = setters;

    const switchShowMedian = (option) => {
        const current = showMedian ? 'median' : 'mean';
        current !== option && toggleShowMedian();
    }

    //switch background color if darkMode changes
    useEffect(() => {

    }, [darkMode]);

    return (
        <div className="ibxxcontrols">
            <UIBox title="Controls" minWidth={minWidth} maxWidth={maxWidth} fullWidth={fullWidth} smallScreen={smallScreen} lightMode={ !darkMode }>
                
                <div className="row">
                    <div className="col-6">

                        <Separator />
                        <h5>General</h5>
                            <span>
                                Select experiment: 
                                <Dropdown 
                                    options={[
                                        {title: 'iBeat', id:'iBeat'}, 
                                        {title: 'iBreath', id: 'iBreath'},
                                    ]} 
                                    callback={setExperiment} 
                                    id="exp"
                                />
                            </span>
                            <br></br>
                            <span>
                                Use trials with
                                <Dropdown 
                                    options={[
                                        {title: 'best', id: 'best'}, 
                                        {title: 'best or medium', id: 'medium'}, 
                                        {title: 'any', id: 'all'}
                                    ]}
                                    callback={setQualityOption}
                                    id="quality"
                                /> 
                                ECG / breath recording  quality.
                            </span>
                            <br></br>
                            <span>
                                Use 
                                <Dropdown 
                                    options={[
                                        {title: 'mean & standard deviation', id: 'mean'}, 
                                        {title: 'median & interquartile range', id: 'median'}, 
                                        {title: 'no filter', id: 'none'}
                                    ]}
                                    callback={setOutlierMetric}
                                    id="filter"
                                /> 
                                for detecting upper outliers.
                            </span>
                            <br></br>
                            <small><i>{'Lower outliers (absLookingTime < 200ms) are always removed.'}</i></small><br></br>
                            <ToggleButton texts={['Show relative plots', 'Hide relative plots']} toggle={toggleShowRelativePlots} lightMode={!darkMode}/>
                            <ToggleButton texts={['Switch to light mode', 'Switch to dark mode']} toggle={toggleDarkMode} lightMode={!darkMode}/>
                        <Separator />
                        <h5>Box plot</h5>
                            <ToggleButton texts={['Show outliers', 'Hide outliers']} toggle={toggleShowOutliers} lightMode={!darkMode}/>
                            <span>
                                Use 
                                <Dropdown 
                                    options={[
                                        {title: 'median', id: 'median'}, 
                                        {title: 'mean', id: 'mean'},
                                    ]}
                                    callback={switchShowMedian}
                                    id="boxplotCenter"
                                /> 
                                as central marker.
                            </span><br />
                            <span>
                                Set number of bins (5-100): <ConstrainedNumericInput 
                                    minValue={5} maxValue={100} 
                                    initValue={numBins} 
                                    callback={setNumBins} 
                                />
                            </span>

                    </div>
                    <div className="col-6">
                        <Separator />
                        <h5>Per-subject views</h5>
                            <span>
                                Use 
                                <Dropdown 
                                    options={['mean', 'median'].map(t => ({title:t, id:t}))} 
                                    callback={setStripchartMetric}
                                    id="plotType"
                                /> 
                                looking time.
                            </span>
                            <ToggleButton texts={['Use color scheme', 'Use solid color']} toggle={toggleUseColorScheme} lightMode={!darkMode}/>
                            <ToggleButton texts={['Switch to scatter plot', 'Switch to strip chart']} toggle={toggleUseScatterplot} lightMode={!darkMode}/>
                        
                        <Separator />
                        <h5>Export</h5>
                        <DownloadButton lightMode={!darkMode}/><br></br>
                        <DownloadTSVButtonIBxx lightMode={!darkMode}/>

                    </div>
                </div>

                
            </UIBox>
            </div>
    );
}
export default IBxxControls;