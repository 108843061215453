import './Dashboard.css';
import { useState, useEffect } from 'react';
import IBxxDashboard from './IBxxDashboard/IBxxDashboard';
import { useWindowSize } from '../Hooks/useWindowSize';

const isSmallScreen = w => w <= 1000;
const makeCompWidth = (smallScreen, innerWidth) => smallScreen ? innerWidth * .92 : innerWidth * .45;

const setInnerWidthSafe = widthFromHook => !widthFromHook || Number.isNaN(widthFromHook) ? window.innerWidth : widthFromHook;

const Dashboard = props => {
    const { experiment } = props;

    //calculate width per component
    const windowSize = useWindowSize();
    const [innerWidth, setInnerWidth] = useState(setInnerWidthSafe(windowSize.width));
    const [smallScreen, setSmallScreen] = useState(isSmallScreen(innerWidth));
    const [compWidth, setCompWidth] = useState(makeCompWidth(smallScreen, innerWidth));

    useEffect(() => {
        setInnerWidth(setInnerWidthSafe(windowSize.width));
    }, [ windowSize.width ]);
    useEffect(() => {
        setSmallScreen(isSmallScreen(innerWidth));
    }, [ innerWidth ]);
    useEffect(() => {
        setCompWidth(makeCompWidth(smallScreen, innerWidth));
    }, [ smallScreen, innerWidth ]);


    const content = (()=>{
        switch(experiment){
            case 'iBxx': 
                return(() => {
                    return (<IBxxDashboard compWidth={compWidth} smallScreen={smallScreen} />);
                })();
            default: 
                return (<></>);
        }
    })();
    return content;
}
export default Dashboard;