import './UIBox.css';

import React from 'react';

const UIBox = props => {
    const { title, minWidth, maxWidth, children, smallScreen, fullWidth, lightMode } = props;

    return (
        <div className={`visContainer uiBox ${lightMode ? 'light' : 'dark'}`} style={smallScreen ? {width: fullWidth} : {minWidth: minWidth, maxWidth: maxWidth}}> 
            <h3>{title}</h3>
            {children}
        </div>
    );
}
export default UIBox;