import '../App/App.css';
import './Boxplot.css';

import React, { useContext, useState, useEffect } from 'react';
import { Group } from '@visx/group';
import { scaleLinear, scaleBand } from '@visx/scale';
import { ViolinPlot, BoxPlot } from '@vx/stats';
import { PatternLines } from '@vx/pattern';
import { AxisLeft, AxisBottom } from '@vx/axis';
import { GridRows } from '@vx/grid';
import { DashboardStateContext } from '../Dashboard/IBxxDashboard/IBxxDashboard';
import { getSubdirPaths } from '../Dashboard/IBxxDashboard/getSubdirPaths';
import getTheme from '../App/theme';
import { padRange } from '../tools/padRange';
import { makeBins } from './bins';
import useFetch from '../Hooks/useFetch';

const subdirPaths = getSubdirPaths();

const makeDataUrls = (experiment, qualityOption, outlierMetric, pov) => {
  //import data & make bins
  const sync = pov === "rel" 
    ? `${window.location.origin}/data/iBxx/${experiment}/${subdirPaths.quality[qualityOption]}/${subdirPaths.filter[outlierMetric]}/boxplot_syncRel.json`
    : `${window.location.origin}/data/iBxx/${experiment}/${subdirPaths.quality[qualityOption]}/${subdirPaths.filter[outlierMetric]}/boxplot_syncAbs.json`;
  const async = pov === "rel" 
    ? `${window.location.origin}/data/iBxx/${experiment}/${subdirPaths.quality[qualityOption]}/${subdirPaths.filter[outlierMetric]}/boxplot_asyncRel.json`
    : `${window.location.origin}/data/iBxx/${experiment}/${subdirPaths.quality[qualityOption]}/${subdirPaths.filter[outlierMetric]}/boxplot_asyncAbs.json`;
  return { sync, async };
}

const makeMean = (binData) => binData.reduce((mean, d) => mean + d.value, 0) / binData.length;

const Boxplot = props => {
  //parse props
  const { width, POV, smallScreen } = props;

  //calc height
  const height = .569 * width;

  //read context
  const dashboardState = useContext(DashboardStateContext);
  const {
    experiment, 
    outlierMetric, 
    qualityOption,
    showOutliers,
    showMedian,
    numBins,
    darkMode
  } = dashboardState;

  //init state
  const [dataUrls, setDataUrls] = useState(makeDataUrls(experiment, qualityOption, outlierMetric, POV));
  const [bins, setBins] = useState([]);
  const [syncData, setSyncData] = useState({});
  const [asyncData, setAsyncData] = useState({});
  const [means, setMeans] = useState([0, 0]);

  //fetch data
  const [fetchDataSync, syncIsLoading] = useFetch({ url: dataUrls.sync });
  const [fetchDataAsync, asyncIsLoading] = useFetch({ url: dataUrls.async });

  //update urls if context changes
  useEffect(() => {
    setDataUrls(makeDataUrls(experiment, qualityOption, outlierMetric, POV));
  }, [experiment, qualityOption, outlierMetric, POV]);

  //update data if fetched data is ready
  useEffect(() => {
    if(!syncIsLoading && !asyncIsLoading && fetchDataAsync.data && fetchDataSync.data){
      setSyncData(fetchDataSync.data);
      setAsyncData(fetchDataAsync.data);
      setBins([fetchDataSync.data, fetchDataAsync.data].map(d => makeBins(d,  numBins)));
      setMeans([fetchDataSync, fetchDataAsync].map(d => makeMean(d.data.binData)));
    }
  }, [fetchDataAsync, fetchDataSync, syncIsLoading, asyncIsLoading, numBins]);
  


  //read theme (the uncool way)
  const theme = getTheme();

  //set colors
  bins.forEach((d, i) => {
    d.color = theme.visColor;
  }); 
  

  // accessors
  const x = (d) => d.boxPlot.x;
  const min = (d) => d.boxPlot.min;
  const max = (d) => d.boxPlot.max;
  const median = (d) => d.boxPlot.median;
  const firstQuartile = (d) => d.boxPlot.firstQuartile;
  const thirdQuartile = (d) => d.boxPlot.thirdQuartile;
  const outliers = (d) => d.boxPlot.outliers;

  //margins
  const marginLeft = smallScreen ? 40 : 60;
  const marginBottom = 40;
  const marginTop = 20;
  const marginRight = 10;

  // bounds
  const xMax = width - marginRight;
  const yMin = marginTop;
  const yMax = height-marginBottom;

  //calc data ranges
  const allVals = bins.reduce((allValues, { boxPlot }) => {
    allValues.push(boxPlot.min, boxPlot.max);
    return allValues;
  }, []);
  if(showOutliers){
    [outliers(syncData), outliers(asyncData)].forEach(
      outlierSet => outlierSet.forEach(
        outlier => allVals.push(outlier)
    ));
  }
  const minYValue = Math.min(...allVals);
  const maxYValue = Math.max(...allVals);

  //make scales
  const domainY = padRange([minYValue, maxYValue], .1);
  const yScale = scaleLinear({
    range: [yMax, yMin],
    round: true,
    domain: domainY,
  });
  const xScale = scaleBand({
    range: [marginLeft, xMax],
    round: true,
    domain: bins.map(x),
    padding: 0.6,
  });

  //make box dims
  const boxWidth = xScale.bandwidth();
  const constrainedWidth = Math.min(150, boxWidth);

  //tick props
  const numTicksY = smallScreen ? 6 : 12;
  const tickLabelPropsX = () => ({
    fill: darkMode ? "#ced4da" : "#111",
    fontFamily: 'sans-serif',
    textAnchor: 'middle',
    dx: '0em',
    dy: '.42em',
    fontSize: smallScreen ? '.8em' : '.9em'
  });
  const tickLabelPropsY = () => ({
    fill: darkMode ? "#ced4da" : "#111",
    fontFamily: 'sans-serif',
    textAnchor: 'end',
    dx: '-0.4em',
    dy: '.42em',
    fontSize: smallScreen ? '.8em' : '.9em'
  });
  return (
      <svg width={width} height={height} className={`visContainer visSVG_saveable ${darkMode ? "dark" : "light"}`}>
        {
          syncIsLoading || asyncIsLoading 
          ? <p className="loading">Loading...</p>
          : <><PatternLines
              id="hViolinLines"
              height={3}
              width={3}
              stroke={darkMode ? "#ced4da" : "#111"}
              strokeWidth={.25}
              strokeOpacity={.25}
              orientation={['horizontal']}
              
            />

            <Group top={0}>
              <AxisBottom
                scale={xScale}
                left={0}
                top={yMax}
                stroke={darkMode ? "#ced4da" : "#111"}
                fill={darkMode ? "#ced4da" : "#111"}
                tickLabelProps={tickLabelPropsX}
              />
              <AxisLeft
                scale={yScale}
                left={marginLeft}
                numTicks={numTicksY}
                stroke={darkMode ? "#ced4da" : "#111"}
                fill={darkMode ? "#ced4da" : "#111"}
                tickLabelProps={tickLabelPropsY}
              />
              <GridRows
                scale={yScale}
                left={marginLeft}
                width={xMax-marginLeft}
                height={yMax}
                strokeDasharray="2, 2"
                numTicks={numTicksY}
                stroke={darkMode ? "#ced4da" : "#111"}
                strokeOpacity={0.3}
                nice="true"
              />
              {bins.map((d, i) => {
                return (
                  <g key={i}>
                    <ViolinPlot
                      data={d.binData}
                      stroke={darkMode ? theme.visColor : theme.visColor_Light}
                      left={xScale(x(d))}
                      width={constrainedWidth}
                      valueScale={yScale}
                      fill="url(#hViolinLines)"
                    />
                    <BoxPlot
                      min={min(d)}
                      max={max(d)}
                      left={xScale(x(d)) + 0.4 * constrainedWidth}
                      firstQuartile={firstQuartile(d)}
                      thirdQuartile={thirdQuartile(d)}
                      median={showMedian ? median(d) : means[i]}
                      boxWidth={constrainedWidth * 0.2}
                      fill={darkMode ? theme.visColor : theme.visColor_Light}
                      fillOpacity={0.3}
                      stroke={darkMode ? theme.visColor : theme.visColor_Light}
                      strokeWidth={2}
                      valueScale={yScale}
                      outliers={showOutliers ? outliers(d) : []}
                      outlierProps={{r: smallScreen ? 1 : 2, strokeWidth: 1}}
                      fi
                      minProps={{}}
                      maxProps={{}}
                      boxProps={{}}
                      medianProps={{
                        style: {
                          stroke: darkMode ? theme.visColor : theme.visColor_Light,
                        }
                      }}
                    />
                  </g>
                );
              })}
            </Group>
          </>
        }
      </svg>
  );
}

export default Boxplot;