import React, {useState, useEffect, useContext} from 'react';
import { DashboardStateContext } from '../../Dashboard/IBxxDashboard/IBxxDashboard';
import { getSubdirPaths } from '../../Dashboard/IBxxDashboard/getSubdirPaths';
import useFetch from '../../Hooks/useFetch';

const subdirPaths = getSubdirPaths();

const TABLE_KEYS = ['trialType', 'time', 'numTrials', 'median', 'avg', 'sd', 'min', 'max', 'q25', 'q75'];

const makeDataUrl = (experiment, qualityOption, outlierMetric) =>
    `${window.location.origin}/data/iBxx/${experiment}/${subdirPaths.quality[qualityOption]}/${subdirPaths.filter[outlierMetric]}/summary.json`;

const makeColData = (data, showRelativePlots) => {

    const columnsRaw = (showRelativePlots ? ['abs', 'rel'] : ['abs']).reduce((cols, time) => {
        ['sync', 'async'].forEach(trialType => cols.push(data[trialType][time]));
        return cols;   
    }, []);

    const columns = columnsRaw.map(columnRaw => 
        TABLE_KEYS.reduce((column, key) => {
            column[key] = columnRaw[key];
            return column;
        }, ({}))
    );
    
    return columns;
};

const makeTableData = columns => {
    const [headKey, ...bodyKeys] =  TABLE_KEYS;
    const head = columns.map(col =>  col[headKey]);
    const body = bodyKeys.map(key => columns.map(col => col[key]));
    return { head, body };
}

const formatTD = val => isNaN(val)
    ? val
    : Number.isInteger(val)
        ? val
        : val.toFixed(2);

const IBxxSummaryTable = props => {
    //parse props
    const { minWidth, maxWidth, smallScreen,  fullWidth } = props;

    //read context
    const dashboardState = useContext(DashboardStateContext);
    const {
        experiment,
        outlierMetric,
        qualityOption,
        showRelativePlots,
        darkMode
    } = dashboardState;

    //init state
    const [dataUrl, setDataUrl] = useState(makeDataUrl(experiment, qualityOption, outlierMetric));
    const [data, setData] = useState({});
    const [columns, setColumns] = useState([]);
    const [tableData, setTableData] = useState({});

    //fetch data
    const [fetchData, isLoading] = useFetch({ url: dataUrl });

    //update data if fetched data changes
    useEffect(() => {
        setData(fetchData.data || {});
    }, [ fetchData ]);

    //reload data if context changes
    useEffect(() => {
        setDataUrl(makeDataUrl(experiment, qualityOption, outlierMetric));
    }, [ experiment, qualityOption, outlierMetric ]);
    useEffect(() => {
        if(!isLoading && data.sync && data.async){
            setColumns(makeColData(data, showRelativePlots));
        }
    }, [ data, showRelativePlots, isLoading ]);
    useEffect(() => {
        setTableData(makeTableData(columns));
    }, [ columns ]);

    return (
        <div className={`visContainer summaryTable ${darkMode ? "dark" : "light"}`} style={ {padding: '10px', fontSize: '.9em', ...(smallScreen ? {width: fullWidth} : {minWidth: minWidth, maxWidth: maxWidth}) } }> 
            { isLoading 
                ? <p className="loading">Loading...</p>
                : <table className={`table ${darkMode ? "table-dark" : "table-light"}`} style={ {backgroundColor: 'transparent'} }>
                    <thead>
                        <tr>
                            { ['Stats', ...tableData.head].map((text, textIndex) => (<th key={`th_${text}_${textIndex}`} scope="col">{ text }</th>)) }
                        </tr>
                    </thead>
                    <tbody>
                        { tableData.body.map((row, rowIndex) => (
                        <tr key={`summarytable_row_${rowIndex}`}>
                            <th scope="row"> {TABLE_KEYS[rowIndex+1]} </th>
                            { row.map((val, valIndex) => (<td key={`td_${rowIndex}_${valIndex}`}> { formatTD(val) } </td>)) }
                        </tr>
                        ))}
                    </tbody>
                </table>
            }
        </div>
    );
};

export default IBxxSummaryTable;
