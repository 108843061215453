const bins = (binData, numBins) => {
    //get range
    const values = binData.map(d => d.value);
    const extrema = {
        min: Math.min(...values),
        max: Math.max(...values)
    };
    const range = extrema.max-extrema.min;

    //initialize bins
    const rangePerBin = range / numBins;
    const newBins = [];
    for(let i=0; i<numBins; ++i){
        const binCenter = extrema.min + (i+.5) * rangePerBin;
        newBins.push({value: binCenter, count: 0});
    }

    //set counters (exclude in and max)
    values.forEach(value => {
        if(value !== extrema.min && value !== extrema.max){
            const positionInRange = (value - extrema.min) / range;
            const _binIndex = Math.round(positionInRange * numBins);
            const binIndex = _binIndex >= numBins ? numBins - 1
                : _binIndex < 0 ? 0: _binIndex;
            newBins[binIndex].count += 1;
        }
        
    });

    //add special bins for min and max
    const minBin = {value: extrema.min, count: 0};
    const maxBin = {value: extrema.max, count: 0};

    return [minBin, ...newBins, maxBin];
}

export const makeBins = (boxplotData, numBins) => {
    const nb = numBins || 10;
    const output = {
        boxPlot: boxplotData.boxPlot,
        outliers: boxplotData.outliers,
        binData: bins(boxplotData.binData, nb)
    };

    return output;
}