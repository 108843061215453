import React, { useState, useContext, useEffect } from 'react';

import { DashboardStateContext } from '../Dashboard/IBxxDashboard/IBxxDashboard';
import { getSubdirPaths } from '../Dashboard/IBxxDashboard/getSubdirPaths';
import Scatterplot from '../Scatterplot/Scatterplot';
import useFetch from '../Hooks/useFetch';
import getTheme from '../App/theme';


const subdirPaths = getSubdirPaths();

const makeDataUrl = (qualityOption, outlierMetric, stripchartMetricId, experiment) => 
    `${window.location.origin}/data/iBxx/${experiment}/${subdirPaths.quality[qualityOption]}/${subdirPaths.filter[outlierMetric]}/stripchartData_${stripchartMetricId}.json`;

const getStripchartMetricId = stripchartMetric => 
    (stripchartMetric === 'mean' || stripchartMetric === 'avg') 
    ? 'avg' 
    : (stripchartMetric === 'median') ? 'median'
        : (() => {
            console.warn(`Invalid stripchartMetric: ${stripchartMetric}. Options are 'mean', 'avg' and 'median'. Defaulting to 'avg'.`);
            return 'avg';
        })();
    
const makeScatterplotData = ({ dataIBeat, dataIBreath }) => {

    //find matching subject ids
    const subjectCodes = Object.keys(dataIBeat)
        .filter(iBeatSubject => Object.keys(dataIBreath).find(iBreathSubject => iBreathSubject.substring(0, 3) === iBeatSubject.substring(0, 3)))
        .map(subjectId => subjectId.substring(0, 3));

    //create [y, x] = [iBeat, iBreath] pairs
    const keys = {sync: `syncAbs`, async: `asyncAbs`};
    const data = subjectCodes.map(subjectCode => {
        const iBeatSync = dataIBeat[`${subjectCode}_ibeat`][keys.sync];
        const iBeatAsync = dataIBeat[`${subjectCode}_ibeat`][keys.async];
        const iBreathSync = dataIBreath[`${subjectCode}_ibreath`][keys.sync];
        const iBreathAsync = dataIBreath[`${subjectCode}_ibreath`][keys.async];

        const diffIBeat = (iBeatSync - iBeatAsync) / (iBeatSync + iBeatAsync);
        const diffIBreath = (iBreathSync - iBreathAsync) / (iBreathSync + iBreathAsync);

        return [diffIBeat, diffIBreath];
    });

    return data;
}


const SubjectTrends = props => {

    const { width, smallScreen } = props;

    //calc height
    const height = .6 * width;

    //get theme (the uncool way)
    const theme = getTheme();

    //read context
    const dashboardState = useContext(DashboardStateContext);
    const {
        outlierMetric, 
        qualityOption,
        stripchartMetric,
        darkMode
    } = dashboardState;

    //init state
    const [stripchartMetricId, setStripchartMetricId] = useState(getStripchartMetricId(stripchartMetric));
    const [dataUrlIBeat, setDataUrlIBeat] = useState(makeDataUrl(qualityOption, outlierMetric, stripchartMetricId, 'iBeat'));
    const [dataUrlIBreath, setDataUrlIBreath] = useState(makeDataUrl(qualityOption, outlierMetric, stripchartMetricId, 'iBreath'));
    const [dataIBeat, setDataIBeat] = useState({});
    const [dataIBreath, setDataIBreath] = useState({});
    const [data, setData] = useState([]);

    //fetch data
    const [fetchDataIBeat, isLoadingIBeat] = useFetch({ url: dataUrlIBeat });
    const [fetchDataIBreath, isLoadingIBreath] = useFetch({ url: dataUrlIBreath });

    //update url if context changes
    useEffect(() => {
        setDataUrlIBeat(makeDataUrl(qualityOption, outlierMetric, stripchartMetricId, 'iBeat'));
        setDataUrlIBreath(makeDataUrl(qualityOption, outlierMetric, stripchartMetricId, 'iBreath'));
    }, [qualityOption, outlierMetric, stripchartMetricId]);

    //update data if fetched data changes
    useEffect(() => {
        if(fetchDataIBeat.data){
            setDataIBeat(fetchDataIBeat.data);
        }
    }, [ fetchDataIBeat ]);
    useEffect(() => {
        if(fetchDataIBreath.data){
            setDataIBreath(fetchDataIBreath.data);
        }
    }, [ fetchDataIBreath ]);
    useEffect(() => {
        setStripchartMetricId(getStripchartMetricId(stripchartMetric));
    }, [ stripchartMetric ]);
    
    useEffect(() => {
        setData(makeScatterplotData({ dataIBeat, dataIBreath }));
    }, [ dataIBeat, dataIBreath ]);



    const plotProps = {
        label: `Proportional per-subject difference of ${stripchartMetric} fixation time in sync and async trials`,
        data: data,
        dims: {
            height: height, 
            width: smallScreen ? width : width/2
        },
        color: darkMode ? theme.visColor : theme.visColor_Light,
        smallScreen,
        axisLabels: ['iBeat', 'iBreath']
    };

    return (
        <>
            <div className={`visContainer ${darkMode ? "dark" : "light"}`}>
                {
                    isLoadingIBeat || isLoadingIBreath
                    ? <p className="loading">Loading...</p>
                    : <svg width={smallScreen ? width : width/2} height={height} className="visSVG_saveable">
                        <Scatterplot {...plotProps} lightMode={!darkMode} />
                    </svg>
                }
            </div>
        </>
    );
};

export default SubjectTrends;
