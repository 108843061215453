import './Plots2d.css';
import React, { useContext, useState, useEffect } from 'react';
import Stripchart from '../Stripchart/Stripchart';
import Scatterplot from '../Scatterplot/Scatterplot';
import { DashboardStateContext } from '../Dashboard/IBxxDashboard/IBxxDashboard';
import getTheme from '../App/theme';
import { getSubdirPaths } from '../Dashboard/IBxxDashboard/getSubdirPaths';
import useFetch from '../Hooks/useFetch';

const subdirPaths = getSubdirPaths();

const makeDataUrl = (experiment, qualityOption, outlierMetric, stripchartMetricId) => 
    `${window.location.origin}/data/iBxx/${experiment}/${subdirPaths.quality[qualityOption]}/${subdirPaths.filter[outlierMetric]}/stripchartData_${stripchartMetricId}.json`;

const splitData = (data) => Object.keys(data).reduce((out, subject) => {
    out.dataAbs.push([
        data[subject].syncAbs,
        data[subject].asyncAbs
    ]);
    out.dataRel.push([
        data[subject].syncRel,
        data[subject].asyncRel
    ]);
    return out;
}, Object({dataAbs: [], dataRel: []}));


const getStripchartMetricId = stripchartMetric => 
    (stripchartMetric === 'mean' || stripchartMetric === 'avg') 
    ? 'avg' 
    : (stripchartMetric === 'median') ? 'median'
        : (() => {
            console.warn(`Invalid stripchartMetric: ${stripchartMetric}. Options are 'mean', 'avg' and 'median'. Defaulting to 'avg'.`);
            return 'avg';
        })();


//Creates 2 2d plots (scatter plot or strip chart) for abs and rel times
const Plots2d = props => {
    const { width, smallScreen } = props;

    //calc height
    const height = .6 * width;

    //get theme (the uncool wy)
    const theme = getTheme();

    //read context
    const dashboardState = useContext(DashboardStateContext);
    const {
        useScatterplot,
        experiment, 
        outlierMetric, 
        qualityOption,
        stripchartMetric,
        showRelativePlots,
        useColorScheme,
        darkMode
    } = dashboardState;

    //init state
    const [stripchartMetricId, setStripchartMetricId] = useState(getStripchartMetricId(stripchartMetric));
    const [data, setData] = useState({});
    const [dataUrl, setDataUrl] = useState(makeDataUrl(experiment, qualityOption, outlierMetric, stripchartMetricId))
    const [dataAbsRel, setDataAbsRel] = useState({});
    const [dataAbs, setDataAbs] = useState({});
    const [dataRel, setDataRel] = useState({});

    //fetch data
    const [fetchData, isLoading] = useFetch({ url: dataUrl });

    //update url if context changes
    useEffect(() => {
        setDataUrl(makeDataUrl(experiment, qualityOption, outlierMetric, stripchartMetricId));
    }, [experiment, qualityOption, outlierMetric, stripchartMetricId]);

    //update data if fetched data changes
    useEffect(() => {
        if(fetchData.data){
            setData(fetchData.data);
        }
    }, [fetchData]);

    useEffect(() => {
        setDataAbsRel(splitData(data));
    }, [data, isLoading]);

    useEffect(() => {
        setDataAbs(dataAbsRel.dataAbs);
        setDataRel(dataAbsRel.dataRel);
    }, [dataAbsRel]);

    useEffect(() => {
        setStripchartMetricId(getStripchartMetricId(stripchartMetric));
    }, [ stripchartMetric ]);


    const plotProps = [
        {
            label: `${stripchartMetricId === 'median' ? 'Median' : 'Mean'} fixation time per subject (ms)`,
            data: dataAbs,
            dims: {
                height: height, 
                width: smallScreen ? width : width/2
            },
            color: !useColorScheme ? (darkMode ? theme.visColor : theme.visColor_Light) : "scale",
            smallScreen,
            axisLabels: ['sync', 'async']
        },
        {
            label: `${stripchartMetricId === 'median' ? 'Median' : 'Mean'} fixation time per subject (%)`,
            data: dataRel,
            dims: {
                height: height, 
                width: smallScreen ? width : width/2
            },
            color: !useColorScheme ? (darkMode ? theme.visColor : theme.visColor_Light) : "scale",
            smallScreen,
            axisLabels: ['sync', 'async']
        }
    ];
    

    return (
        <div className="plots2dParent">
            <div className="plots2dContainer">
                <div className={`visContainer ${darkMode ? "dark" : "light"}`}>
                    {
                        isLoading 
                        ? <p className="loading">Loading...</p>
                        : <svg width={smallScreen ? width : width/2} height={height} className="visSVG_saveable">
                            {useScatterplot 
                                ? <Scatterplot {...plotProps[0]} lightMode={!darkMode} />
                                : <Stripchart {...plotProps[0]} lightMode={!darkMode} />
                            }
                        </svg>
                    }
                </div>
                { showRelativePlots && 
                    <div className="visContainer">
                        {
                            isLoading
                            ? <p className="loading">Loading...</p>
                            : <svg width={smallScreen ? width : width/2} height={height} className="visSVG_saveable">
                                {useScatterplot 
                                    ? <Scatterplot {...plotProps[1]} lightMode={!darkMode} />
                                    : <Stripchart {...plotProps[1]} lightMode={!darkMode}/>
                                }
                            </svg>
                        }
                    </div>
                }
            </div>
        </div>
        
    );
}
export default Plots2d;