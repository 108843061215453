export const getSubdirPaths = () => ({
    filter: {
        none: 'noFilter',
        mean: 'meanFilter',
        avg: 'meanFiler',
        median: 'medianFilter'
    },
    quality: {
        all: 'all',
        medium: 'medium',
        best: 'best'
    },
});