import React, { useState } from 'react';
import Boxplot from '../../Boxplot/Boxplot';
import Plots2d from '../../Plots2d/Plots2d';
import IBxxSummaryTable from '../../Tables/IBxxSummaryTable/IBxxSummaryTable';
import IBxxControls from '../../Controls/IBxxControls/IBxxControls';
import IBxxLegends from '../../Legends/IBxxLegends/IBxxLegends';
import SubjectTrends from '../../SubjectTrends/SubjectTrends';
import getTheme from '../../App/theme';

export const DashboardStateContext = React.createContext(null);

const setBackgroundColor = darkMode => {
    const theme = getTheme();
    const body = document.getElementsByTagName("body")[0];
    body.style["background"] = darkMode ? theme.backgroundColor : theme.backgroundColor_Light;
}

const IBxxDashboard = props => {
    //Read props
    const { compWidth, smallScreen } = props;

    //Initialize state
    const [showOutliers, setShowOutliers] = useState(false);
    const [showMedian, setShowMedian] = useState(true);
    const [useColorScheme, setUseColorScheme] = useState(false);
    const [experiment, setExperiment] = useState('iBeat');
    const [qualityOption, setQualityOption] = useState('best');
    const [outlierMetric, setOutlierMetric] = useState('mean');
    const [stripchartMetric, setStripchartMetric] = useState('mean');
    const [useScatterplot, setUseScatterplot] = useState(false);
    const [showRelativePlots, setShowRelativePlots] = useState(false);
    const [numBins, setNumBins] = useState(15);
    const [darkMode, setDarkMode] = useState(true);

    //Wrap state toggles
    const toggleShowOutliers = () => setShowOutliers(!showOutliers);
    const toggleShowMedian = () => setShowMedian(!showMedian);
    const toggleUseColorScheme = () => setUseColorScheme(!useColorScheme);
    const toggleUseScatterplot = () => setUseScatterplot(!useScatterplot);
    const toggleShowRelativePlots = () => setShowRelativePlots(!showRelativePlots);
    const toggleDarkMode = () => {
        setBackgroundColor(!darkMode);
        setDarkMode(!darkMode);
    };
    const toggles = { 
        toggleShowOutliers, 
        toggleShowMedian, 
        toggleUseColorScheme, 
        toggleUseScatterplot, 
        toggleShowRelativePlots, 
        toggleDarkMode 
    };
    
    //Wrap state setters
    const setters = { setExperiment, setStripchartMetric, setOutlierMetric, setQualityOption, setNumBins };

    return (
        <div id="dashboard">
            <DashboardStateContext.Provider value={{
                experiment, qualityOption, 
                outlierMetric, stripchartMetric,
                useScatterplot, useColorScheme, 
                showOutliers, showRelativePlots,
                showMedian, darkMode,
                toggles, setters,
                numBins
            }}>
                <Boxplot 
                    width={compWidth}
                    POV='abs' 
                    smallScreen={smallScreen} 
                />
                { showRelativePlots &&
                    <Boxplot 
                        width={compWidth} 
                        POV='rel' 
                        smallScreen={smallScreen} 
                    />
                }   
                <IBxxControls 
                    minWidth={compWidth} maxWidth={1.8*compWidth} 
                    smallScreen={smallScreen} fullWidth={compWidth}
                />
                <Plots2d
                    width={compWidth} 
                    smallScreen={smallScreen} 
                />   
                <IBxxLegends
                    minWidth={compWidth/3} maxWidth={compWidth * .4}
                    smallScreen={smallScreen} fullWidth={compWidth}
                />      
                <IBxxSummaryTable 
                    minWidth={compWidth/2} maxWidth={2*compWidth} 
                    smallScreen={smallScreen} fullWidth={compWidth}
                />   
                <SubjectTrends
                    width={compWidth} 
                    smallScreen={smallScreen} 
                /> 
                
            </DashboardStateContext.Provider>
        </div>
    );
}
export default IBxxDashboard;