import getTheme from '../App/theme';
import { scaleOrdinal } from '@visx/scale';

const angleColorScale = maxYValue => {
    /*
    Colors represent the angle (aka derivative) of a line. These angles are in range ]-90, 90[.
    We are interested in the subrange [minAngle, maxAngle]
    This range is split into 9 bins, each representing a range of (maxAngle-minAngle)/numBins degrees
    IMORTANT: This scale only works for positive values;
    */
   const numBins = 9;
   const minAngle = -30;
   const maxAngle = 30;
   const angleRange = maxAngle-minAngle;
   const angleLimits = []; //for angleRange: [-45, 45] => [-35, -25, ... , 35, 45]
   for(let i=0; i<numBins; ++i){
     const nextLimit = minAngle + (i+1) * angleRange/numBins;
     angleLimits.push(nextLimit);
   }
   const normalizer = maxYValue < 1 ? 1 : maxYValue;
   const normalizeY = y => y/normalizer;
   const radToDeg = rad => (rad%180) * (180/Math.PI);
   const getAngleLimit = (y0, y1) => {
     const dy = normalizeY(y1)-normalizeY(y0);
     const angle = radToDeg(Math.asin(dy));
     const angleLimit = angle > maxAngle
       ? angleLimits[angleLimits.length - 1]
       : angleLimits.find(limit => angle <= limit);
     
     return angleLimit;
   }
   const theme = getTheme();
   const scale = scaleOrdinal({
       domain: angleLimits,
       range: theme.scheme[numBins].reverse(),
   });
   return { scale, getAngleLimit };
}
export default angleColorScale;